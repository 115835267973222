import './distributiva_mundos.scss';

import React from 'react';
import { graphql } from 'gatsby';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import Modi from '../src/components/Modi/Modi';
import Tyr from '../src/components/Tyr/Tyr';
import Ymir from '../src/components/Ymir/Ymir';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import logo from '../src/images/pap.png';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query MundosQuery($locale: String!) {
    allZonesSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allZonesTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          name
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allDiscoverParks(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          MainImage {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          Image
          altImage
          Title
          TitleURL
          Text
          button1 {
            cta
            size
            color
            ctaText
          }
          button2 {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allMundosPromotedSubBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          cta
          ctaText
          title
          localImage {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 160, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allDiscoverParksMundos(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allDistMundosBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
          destination_url
        }
      }
    }
    allDistMundosRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class Mundos extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allZonesTitleBlock.edges[0].node.name,
      image: {
        alt: this.props.data.allZonesTitleBlock.edges[0].node.alt,
        url: this.props.data.allZonesTitleBlock.edges[0].node.localImage.childImageSharp.fluid,
      },
    };

    const modiData = {
      Image: logo,
      Text: tt(
        'Descubre los seis mundos de uno de los parques temáticos más emblemáticos de Europa. Un destino ideal para una escapada en familia, situado en una de las mejores zonas turísticas de España ¡y a tan solo una hora de Barcelona!',
        this.props.pageContext.locale
      ),
      button1: {
        cta: tt('/entradas', this.props.pageContext.locale),
        size: 'alone',
        color: 'orange',
        ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
      },
    };

    const ymirData = {
      cards: [
        {
          title: tt('HORARIOS Y CALENDARIO', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/horarios-calendario', this.props.pageContext.locale),
          icon: 'calendar',
        },
        {
          title: tt('CÓMO LLEGAR AL RESORT', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/destino/planificar/como-llegar-a-portaventura', this.props.pageContext.locale),
          icon: 'LOCATION',
        },
        {
          title: tt('DESCUENTOS EN ENTRADAS ONLINE', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/promociones', this.props.pageContext.locale),
          icon: 'Methodofpayment',
        },
      ],
    };

    const mundosData = {
      promotions: this.props.data.allMundosPromotedSubBlock.edges,
    };

    const h2Data = {
      title: this.props.data.allDiscoverParksMundos.edges[0].node.name,
    };

    const prepareForTyr = (data) => ({
      cards: data.edges.map((item) => ({
        MainImage: item.node.MainImage.childImageSharp.fluid,
        Image: item.node.Image,
        altImage: item.node.altImage,
        Title: item.node.Title,
        TitleURL: item.node.TitleURL,
        Text: item.node.Text,
        button1: item.node.button1,
        button2: item.node.button2,
      })),
    });

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: mundosData.promotions.map((mundo) => ({
        image: mundo.node.localImage.childImageSharp.fluid,
        altImage: mundo.node.image_alt,
        title: mundo.node.title,
        imgsize: 'promoted',
        buttons: {
          cta: mundo.node.cta,
          size: 'alone',
          color: 'white',
          ctaText: mundo.node.ctaText,
        },
      })),
    };
    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allZonesSeoData.edges[0].node._0.title}
          description={this.props.data.allZonesSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allDistMundosRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allZonesTitleBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="zones-content">
              <Modi data={modiData} />
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allDistMundosBreadCrumbBlock.edges[0].node.name}
              />
              <BlueArrow />
              <H2 data={h2Data} />
              <Tyr data={prepareForTyr(this.props.data.allDiscoverParks)} />
              <Ymir data={ymirData} />
              <div className="promotions-content">
                <H4 title={freyaData.title} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Mundos;
